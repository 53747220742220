import { useState } from 'react';


interface ConfirmPromise {
  resolve: (value: any) => void;
  reject: () => void;
}

interface ConfirmHandlers {
  handleClose: () => void;
  handleCancel: () => void;
  handleConfirm: (event?: any) => void;
  confirm: () => Promise<any>;
}

const useConfirm = (): [boolean, ConfirmHandlers] => {
  const [promise, setPromise] = useState<ConfirmPromise | null>(null);

  const confirm = (): Promise<any> =>
    new Promise((resolve, reject) => {
      setPromise({ resolve, reject });
    });

  const handleClose = (): void => {
    promise?.reject();
    setPromise(null);
  };

  const handleConfirm = (event: any = {}): void => {
    promise?.resolve(event);
    handleClose();
  };

  const handleCancel = (): void => {
    promise?.resolve(false);
    handleClose();
  };

  const openDialog = promise !== null;
  return [openDialog, { handleClose, handleCancel, handleConfirm, confirm }];
};

export default useConfirm;
