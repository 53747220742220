import {
    BADGETYPE,
    Badge,
    Breadcrumb,
    Button,
    ColumnDef,
    DialogBox,
    ISolutionvalues,
    Icon,
    IconInput,
    ImagePreview,
    Message,
    NoteProps,
    PanelCard,
    Tab,
    TabButton,
    TabList,
    TabPanel,
    Table,
    TimelineEventProps,
    TooltipWithChildren,
    Typhography,
    VersionDateDropdown,
    usePagination,
    useSorting,
} from 'dfh-ui-library';
import patientHOC from '../index';
import {
    IFormLetter,
    IFormResoureseData,
    IPatient,
    IPatientChallengeDto,
    IPatientProps,
    IPatientResources,
    ISaveChallengeNotesRequest,
    IServeyNoteRequest,
    ISolution,
    ISolutionNoteRequest,
    ISolutionRequest,
    ITodoToggleRequestParams,
} from 'utils/types/patient.type';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import RouteRegistry from 'routes/routeRegistry';
import { getFristLettersFromString, getRandomBgColor } from '../helpers/userHelpers';
import { BuildingIcon, EditIcon, EmailIcon, InfoIcon40, PhoneIcon, UserIcon, WorldIcon } from '../helpers/iconHelper';
import moment from 'moment';
import PatientScreener from './PatientScreener';
import { useSelector } from 'react-redux';
import { ISchema } from 'dfh-form-generator';
import { RootState } from 'stores/store';
import { isEqual } from 'lodash';
import { getProgress } from '../helpers/screenerHelper';
import TimelineAndHistory from '../components/timelineAndHistory';
import { SchemaState } from 'stores/screenerSlice';
import ChallengePannel from '../components/challengePannel';
import {
    CHALLENGE_URGENCY,
    LETTER_GRID_ACTION_TYPES,
    TIMELINE_NOTE_TYPE,
    TIMELINE_TYPES,
    TabValue,
    DOC_TYPES,
    DOC_TAB_TYPES,
} from 'utils/enum';
import LoadingProgress from 'components/Loading/LoadingProgress';
import { FormUploadModal } from '../components/formUploadModal';
import { IPatientResourceRequest } from 'utils/types/image.type';
import { filterOptions } from '../helpers/default';
import { ApiResponse, ISchemaRequestParams } from 'utils/types/api.type';
import _ from 'lodash';
import { getLetterGenParams } from 'stores/letterFormSlice';
import { PdfPreviewModal } from '../components/PdfPreviewModal';
import { INavigationProps } from 'utils/types/screener.type';
import ChallengeTimeline from '../components/challengeTimeline';
import { shortContent } from '../../../utils/helpers.ts';
import useConfirm from 'hooks/useConfirm.ts';
import { BlockNavigation } from 'hooks/useBlockNavigation.tsx';

const PatientDetail = (props: IPatientProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const receivedData = location.state;
    const { patientId } = useParams();
    const [patient, setPatient] = useState<IPatient | undefined>(undefined);
    const [bgColorRandom, setBgColorRandom] = useState('');
    const [patientSchema, setPatientSchema] = useState<SchemaState | undefined>(undefined);
    const [activeTab, setActiveTab] = useState('1');
    const [startScreening, setStartScreening] = useState<boolean>(false);
    const [viewOnlyMode, setViewOnlyMode] = useState<boolean>(false);
    const [showChallengeTimeline, setShowChallengeTimeline] = useState<boolean>(false);
    const [selectedChallenge, setSelectedChallenge] = useState<string>();
    const screenerData = useSelector((state: RootState) => state.screener.data);
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [showNote, setShowNote] = useState(false);
    const [formEditData, setFormEditData] = useState<IFormResoureseData>({});
    const [isLetterSchemaIsLoading, setIsLetterSchemaIsLoading] = useState(false);
    const resourceTypes = useSelector((state: RootState) => state.app.resourceTypes);
    const [error, setError] = useState('')
    const [versions, setVersions] = useState<
        {
            displayText: string;
            value: string;
        }[]
    >([]);

    const [selectedVersionDate, setSelectedVersionDate] = useState<{
        displayText: string;
        value: string;
    }>({
        displayText: '',
        value: '',
    });
    const [selectedVersion, setSelectedVersion] = useState<ISchema | undefined>(undefined);
    const { data: versionData } = props.screenerVersions({ patientId: patientId ? patientId : '' });
    const { data: version, isFetching: versionIsLoading } = props.getPatientSchemaVersion({
        patientId: patientId ? patientId : '',
        dateVersioned: selectedVersionDate.value,
    });

    const {
        data: selectedPatient,
        isFetching: selectedPatientIsLoading,
        refetch,
    } = props.getPatient({
        id: patientId || '',
    });
    const [challengeData, setChallengeData] = useState<any>([]);
    const [challengeCount, setChallengeCount] = useState<number>(0);
    const [solution, setSolution] = useState<ISolution[] | undefined>(undefined);
    const [actionData, setActionData] = useState<ISolution>();
    const [solutionReq, setSolutionReq] = useState<ISolutionRequest>({ challengeId: '', patientChallengeId: '' });
    const {
        data: resourcesData,
        isFetching: isResourcesLoading,
        refetch: resourceRefetch,
    } = props.getResources(patientId ?? '');
    const [solutionRowClick, setSolutionRowClick] = useState(false);
    const [formData, _setFormData] = useState<IFormLetter[]>([]);
    const [patientResourceData, setPatientResourceData] = useState<IPatientResources[]>([]);
    const [showSolutionLongDescription, setShowSolutionLongDescription] = useState<boolean>(false);

    const { onPaginationChange, pagination } = usePagination(100000, 0);
    const { sorting, onSortingChange } = useSorting('resourceName', 'desc');
    const [totalRecords, setTotalRecords] = useState(0);
    const [_rowSelection, setRowSelection] = useState<any>();
    const isManualPagination = false;
    const isManualSorting = true;
    const [formConfig, setFormConfig] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchEnable, setSerchEnable] = useState(false);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [key, _setKey] = useState(0);
    const [isNewDoc, setIsNewDoc] = useState(true);
    const [timeLineData, setTimeLineData] = useState<TimelineEventProps[]>([]);
    const [clickedId, setclickedId] = useState<string>('');
    const {
        data: timeLineDataResponse,
        isFetching: timeLineDataLoading,
        refetch: timeLineRefetch,
    } = props.getPatinentTimeLine(patientId ? patientId : '');
    const [viewSolutionPdf, setViewolutionPdf] = useState<boolean>(false);
    const [copiedNote, setCopiedNote] = useState<NoteProps>();
    const [rowDetails, setRowDetails] = useState<IPatientChallengeDto>();

    const [confirmNavigate, navigateDialogProps] = useConfirm();


    useMemo(() => {
        if (isEqual(patientId, null)) navigate(RouteRegistry.patient.paths.list.path);
    }, []);


    const handleLocationChange = async (e: any) => {
        e.preventDefault();
        if (startScreening) {
            const isConfirm = await navigateDialogProps.confirm();
            if (!isConfirm) {
                // Prevent navigation if user cancels
                window.history.pushState(null, '', window.location.pathname);
            } else {
                window.history.back();
            }
        }
    };

    const handleBlockedNavigation = async () => {
        const isConfirm = await navigateDialogProps.confirm();
        return isConfirm;
    };

    useEffect(() => {

        if (startScreening) {
            window.history.pushState(null, '', window.location.pathname);
            window.addEventListener('popstate', handleLocationChange);
        }
        // Cleanup
        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, [startScreening]);

    useMemo(() => {
        if (timeLineDataResponse) {
            const selectedType = TIMELINE_TYPES['4'] as string;
            const tData = timeLineDataResponse.data.map((value: any) =>
                !_.isEmpty(value.note)
                    ? {
                        ...value,
                        isLocked: value.type == selectedType && value.isLocked !== undefined ? value.isLocked : true,
                    }
                    : {
                        ...value,
                        isLocked: value.type == selectedType && value.isLocked !== undefined ? value.isLocked : true,
                    },
            );

            const timeLineDataWithLockedSurveys = tData.filter((data: any) => {
                return !(data.type === 'Survey' && data.isLocked === false);
            });

            setTimeLineData(timeLineDataWithLockedSurveys);
        }
    }, [timeLineDataResponse]);

    useMemo(() => {
        if (selectedPatient?.data) setPatient(selectedPatient?.data);
    }, [selectedPatient]);

    useMemo(() => {
        if (screenerData.filter((data) => isEqual(data.patientId, patient?._id)).length !== 0) {
            setPatientSchema(screenerData.filter((data) => isEqual(data.patientId, patient?._id))[0].schema);
        }
    }, [screenerData, patient]);

    useMemo(() => {
        setBgColorRandom(getRandomBgColor());
    }, []);

    useMemo(() => {
        if (version && selectedVersionDate.value) {
            setSelectedVersion(version?.data);
        }
    }, [version]);

    useEffect(() => {
        // Check if the query has loaded or is fetching data
        if (selectedPatientIsLoading || selectedPatient) {
            if (receivedData?.refetch) refetch();
        }
    }, [refetch]);

    const letterGenParams = useSelector((state: RootState) => getLetterGenParams(state));

    useEffect(() => {
        if (letterGenParams && letterGenParams.patientChallengeId !== '') {
            setclickedId(letterGenParams.patientChallengeId);
            const sReq = {
                challengeId: letterGenParams.tempChallageId ?? '',
                patientChallengeId: letterGenParams.patientChallengeId ?? '',
            };
            getSolutionData(sReq, letterGenParams.patientChallengeId);
        }

        // Check if the query has loaded or is fetching data
        if (receivedData && receivedData.isEnableSolution) {
            setActiveTab(TabValue.Challenges);
        }

        if (receivedData && receivedData.challengeId) {
            setclickedId(receivedData.challengeId);
        }
    }, [receivedData, letterGenParams]);

    useEffect(() => {
        if (receivedData && receivedData.activeTab) {
            setActiveTab(receivedData.activeTab);
        }
    }, [receivedData]);

    useMemo(() => {
        if (versionData?.data) {
            const versionObj: { displayText: string; value: string }[] = versionData.data.map((date: string) => ({
                displayText: moment(date).format('MM/DD/YYYY'),
                value: date,
            }));
            // Sort the versionObj array in descending order
            versionObj.sort((a, b) => moment(b.value).valueOf() - moment(a.value).valueOf());
            setVersions(versionObj);
        }
    }, [versionData]);

    useEffect(() => {
        const patientScreenerData = screenerData.filter((data) => isEqual(data.patientId, patient?._id))[0];
        if (patientScreenerData) {
            const progress = getProgress(patientSchema);
            if (progress !== 0 || (patientSchema?.completedOn && moment(patientSchema?.completedOn).isValid())) {
                setViewOnlyMode(true);
            }
            if (!patientScreenerData.schema.isLocked) {
                setIsEditable(true);
            }
        }
    }, [patientSchema]);

    const handleClick = async (newValue: string) => {
        if (startScreening) {
            try {
                const isConfirm = await navigateDialogProps.confirm();
                if (isConfirm) {
                    setStartScreening(false);
                    setActiveTab(newValue);
                } else {
                    return Promise.reject()
                }
            } catch (error) {
                console.log(error);
                return Promise.reject();
            }
        } else {
            setActiveTab(newValue);
        }

    };

    const startScreeningProcess = () => {
        setStartScreening(!startScreening);
    };

    const saveAndExit = () => {
        setViewOnlyMode(true);
        startScreeningProcess();
    };

    const handleSelectedValuesChange = (newValues: { displayText: string; value: string }) => {
        setSelectedVersionDate(newValues);
    };

    const backFromVersions = () => {
        setSelectedVersionDate({
            displayText: '',
            value: '',
        });
        setSelectedVersion(undefined);
    };

    const goToVersions = () => {
        setSelectedVersionDate({
            displayText: moment(versions[0]?.value).format('MM/DD/YYYY'),
            value: versions[0]?.value,
        });
    };

    const sortChallenges = (value: string, chalData: any) => {
        const tempData = chalData;
        setChallengeData([]);
        if (value === 'newest') {
            const sortedChallenges = [...tempData].sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)));

            setChallengeData(sortedChallenges);
        } else if (value === 'oldest') {
            const sortedChallenges = [...tempData].sort((a, b) => moment(a.createdDate).diff(moment(b.createdDate)));

            setChallengeData(sortedChallenges);
        } else {
            const sortedChallengesU = [...tempData].sort((a, b) => {
                if (a.urgency === 'Urgent' && b.urgency !== 'Urgent') {
                    return -1; // a comes before b
                } else if (a.urgency !== 'Urgent' && b.urgency === 'Urgent') {
                    return 1; // b comes before a
                } else {
                    return 0; // no change in order
                }
            });
            setChallengeData([]);
            setChallengeData(sortedChallengesU);
        }
        setChallengeCount(chalData?.length);
    };

    useEffect(() => {
        const fetchData = async () => {
            getChallengeData();
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (activeTab === TabValue.Challenges) {
                getChallengeData();
            }
        };
        fetchData();
    }, [activeTab]);

    const getChallengeData = async () => {
        const response = await props.getChallenges({
            patientId: patientId ?? '',
            isResolved: true,
        });
        if (response) {
            sortChallenges(CHALLENGE_URGENCY.URGENT, response.data?.data);
            timeLineRefetch();
        }
    };

    const getSolutionData = async (reqParams: ISolutionRequest, selectedId?: string) => {
        setSolutionReq(reqParams);
        setSolution([]);
        const response = await props.getSolutionRowData(reqParams);
        if (response) {
            setSolution(response?.data?.data);
            if (selectedId) {
                setclickedId(selectedId);
            }
            timeLineRefetch();
        }
    };

    const makeUrgent = async (status: string, id: string, event: any) => {
        event.stopPropagation();
        const response = await props.makeChallengeUrgent({ id: id, urgency: status });
        if (response) {
            getChallengeData();
        }
    };

    const updateChallenge = async (items: ISaveChallengeNotesRequest) => {
        const response = await props.saveChallengeNotes({ ...items, patientId: patientId });
        if (response) {
            getChallengeData();
        }
    };

    const toggleTimelineTodoCheckbox = async (req: ITodoToggleRequestParams) => {
        await toggleTimelineTodoCheckboxEndPoint(req);
    };

    const toggleTimelineTodoCheckboxEndPoint = async (req: ITodoToggleRequestParams) => {
        const response = await props.toggleTodoCheckbox({ ...req, patient: patientId });

        if (response) {
            timeLineRefetch();
        }
    };

    const saveSolutionNote = async (req: ISolutionNoteRequest) => {
        const response =
            patientId &&
            solutionReq.patientChallengeId &&
            (await props.saveSolutionNote({
                ...req,
                patient: patientId,
                patientChallenge: solutionReq.patientChallengeId,
            }));
        if (response) {
            getChallengeData();
            setSolutionRowClick(true);
            setShowSolutionLongDescription(false);
        }
        setShowNote(false);
    };

    const saveServeyNote = async (req: IServeyNoteRequest): Promise<ApiResponse | undefined | ''> => {
        if (req.note._id !== '') {
            const responseUpdate =
                patientId &&
                (await props.updateNotes({
                    ...req,
                    patient: patientId,
                }));
            if (responseUpdate) {
                timeLineRefetch();
            }
            return responseUpdate;
        } else {
            switch (req.noteType) {
                case TIMELINE_NOTE_TYPE.SURVEY:
                    const responseS =
                        patientId &&
                        (await props.updateServeyNotes({
                            ...req,
                            patient: patientId,
                        }));
                    if (responseS) {
                        timeLineRefetch();
                    }
                    return responseS;
                    break;
                case TIMELINE_NOTE_TYPE.NORMAL:
                    const responseN =
                        patientId &&
                        (await props.saveServeyNotes({
                            ...req,
                            patient: patientId,
                        }));
                    if (responseN) {
                        timeLineRefetch();
                    }
                    return responseN;

                default:
                    return undefined;
            }
        }
    };

    const savePatientResource = async (req: IPatientResourceRequest) => {
        let response = null;
        if (req.resourceId) {
            response =
                patientId &&
                (await props.editPatientResource({
                    ...req,
                    patientId: patientId,
                }));
        } else {
            response =
                patientId &&
                (await props.savePatientResource({
                    ...req,
                    patientId: patientId,
                }));
        }

        if (response) {
            setTimeout(() => {
                resourceRefetch();
            }, 1000);

            setFormConfig(false);
        }
    };

    const cancelAction = () => {
        setSolutionRowClick(true);
        // setShowNotification(true);
        setShowSolutionLongDescription(false);
    };

    const rowClick = (_row: any) => {
        //  props.navigateToEdit(patientId ?? '');
    };

    const rowIconClick = () => {
        props.navigateToEdit(patientId ?? '');
    };

    const getLoadingState = (): boolean => {
        const ldState =
            props.isLoading ||
            versionIsLoading ||
            selectedPatientIsLoading ||
            isResourcesLoading ||
            isLetterSchemaIsLoading ||
            isDowndLoading ||
            timeLineDataLoading;
        versionIsLoading;
        return ldState;
    };

    const [selectedValues, setSelectedValues] = useState<string>('alldocs');

    const addNewDocument = () => {
        setFormEditData({});
        setFormConfig(true);
        setIsNewDoc(true);
        setGridCurrentAction(null);
    };




    const controllers = (
        <>
            {!selectedVersionDate.value && versions.length > 0 && (
                <Button
                    additionalClasses='text-blue font-medium border-0 !p-0 !h-4'
                    buttonLabel='Versions'
                    data-testId='InputField-id'
                    iconAlignment='left'
                    iconType='ClockCounterIcon'
                    isIconEnabled
                    variants='primaryWhiteNoBoader'
                    onClick={goToVersions}
                />
            )}
            {/* <Button
                additionalClasses={`text-blue font-medium ${selectedVersionDate.value && 'pt-6'}`}
                buttonLabel='Print'
                data-testId='InputField-id'
                iconAlignment='left'
                iconType='Printer'
                isIconEnabled
                variants='primaryWhiteNoBoader'
            /> */}
            {selectedVersionDate.value && versions.length > 0 && (
                <>
                    <Typhography component='div' additionalClasses='pr-4 text-21s flex items-center'>
                        Version dated
                    </Typhography>
                    <VersionDateDropdown
                        options={versions}
                        selectedValues={
                            selectedVersionDate.value
                                ? selectedVersionDate
                                : {
                                    displayText: moment(versions[0].value).format('MM/DD/YYYY'),
                                    value: versions[0].value,
                                }
                        }
                        handleSelectedValuesChange={handleSelectedValuesChange}
                        additionalClasses='w-full'
                    />
                </>
            )}
            {/* {!selectedVersionDate.value && (
                <Button buttonLabel={'See solutions'} additionalClasses={`text-nowrap`} variants='primaryBlue' />
            )} */}
        </>
    );

    // Focus the input when it becomes visible
    useEffect(() => {
        if (searchEnable && searchInputRef && searchInputRef.current) {
            searchInputRef?.current?.focus();
        }
    }, [searchEnable]);

    const setSearchInput = () => {
        setSerchEnable((prev) => !prev);
    };

    const setSearchTextOnChange = (e: any) => {
        setSearchText(e.target.value);
    };

    const handleFileAction = async (url: string, fileName: string, action: string): Promise<void> => {
        if (action === "print") {
            const element = document.createElement('a');
            element.href = url;
            element.target = '_blank';
            element.click();
        } else if (action === "download") {
            const response = await fetch(url);
            if (!response.ok) throw new Error(`Failed to fetch file from ${url}`);
            const blob = await response.blob();
            const tempUrl = URL.createObjectURL(blob);

            const anchor = document.createElement("a");
            anchor.href = tempUrl;
            anchor.download = fileName;
            anchor.click();
            URL.revokeObjectURL(tempUrl);
        } else {
            console.error("Invalid action. Use 'print' or 'download'.");
        }
    };

    useEffect(() => {
        if (resourcesData) {
            const resData = resourcesData.data; // Assuming resourcesData is always defined when it exists.

            if (searchText !== '') {
                const filteredData = resData.filter((value: any) =>
                    value.resourceName.toLowerCase().includes(searchText.toLowerCase()),
                );
                setPatientResourceData(filteredData);
            } else {
                setPatientResourceData(resData);
            }

            setTotalRecords(resData.length);
        }
    }, [resourcesData, searchText]);

    const getSelectedTabNumber = (selectedTab: string): number | undefined => {
        if (selectedTab in DOC_TAB_TYPES) {
            return DOC_TAB_TYPES[selectedTab as keyof typeof DOC_TAB_TYPES];
        } else {
            return undefined;
        }
    };

    const getSelectedDocType = (tabNumber: number): string | undefined => {
        return DOC_TYPES[tabNumber];
    };

    const onClickTab = (_item: string) => {
        const tabNumber = getSelectedTabNumber(_item);
        let filteredData: IPatientResources[] = [];

        if (resourcesData && tabNumber !== undefined) {
            const resData = resourcesData?.data;
            const docType = getSelectedDocType(tabNumber);

            if (docType !== 'AllDocs' && docType !== undefined) {
                filteredData = resData.filter((resource: IPatientResources) => resource.uploadType === docType);
                setPatientResourceData(filteredData);
            } else if (docType === 'AllDocs') {
                setPatientResourceData(resData);
            } else {
                console.info('Type name is undefined');
            }
            setTotalRecords(resourcesData?.data.length);
        } else {
            console.info('Selected action is undefined');
        }
    };

    const controllersFormLetter = (
        <>
            <Typhography component='div' additionalClasses='flex gap-2'>
                <TabButton
                    options={filterOptions}
                    onClickTab={onClickTab}
                    setSelectedValues={setSelectedValues}
                    selectedValues={selectedValues}
                    additionalClasses='gap-6'
                />

                {searchEnable ? (
                    <div className='flex'>
                        <IconInput
                            type='text'
                            value={searchText}
                            onChange={setSearchTextOnChange}
                            // placeholder='Search By Name'
                            ref={searchInputRef}
                            isIconLeft={searchEnable ? true : false}
                            leftIcon={'Search'}
                            iconClassLeft='absolute top-3 left-4'
                            labelSpanClasses='text-21s'
                            additionalClasses='mt-0 h-10'
                            onBlur={setSearchInput}
                        />
                    </div>
                ) : (
                    <Typhography
                        component='div'
                        additionalClasses='border flex h-10 items-center justify-center rounded-md p-2 cursor-text'
                        onClick={setSearchInput}
                    >
                        <Icon iconType='Search' color='#000' />
                    </Typhography>
                )}
                <Button
                    buttonLabel='Add new document'
                    data-testId='InputField-id'
                    iconAlignment='left'
                    iconType='WhitePlus'
                    isIconEnabled={true}
                    additionalClasses={`text-nowrap !h-10 !px-3`}
                    variants='primaryBlue10'
                    onClick={addNewDocument}
                />
            </Typhography>
        </>
    );

    const navigateLetter = async (
        challengeId: string,
        veriantId: string,
        varient: string,
        solutionId: string,
        tempChallageId: string,
    ) => {
        setIsLetterSchemaIsLoading(true);
        const req: ISchemaRequestParams = {
            patientChallengeId: challengeId,
            varientId: veriantId,
            patientId: patientId,
        };

        const response = await props.getPatientLetterSchemaVersion(req);
        if (response.error && 'data' in response.error) {
            setError(`This particular solution was not found. Please report this error and include variant ID ${veriantId}`);
            return
        }
        setIsLetterSchemaIsLoading(response.isFetching);
        const navProps: INavigationProps = {
            id: patientId ?? '',
            name: `${patient?.firstName || ''} ${patient?.lastName || ''}`,
            challengeId: challengeId,
            varientId: veriantId,
            varient: varient,
            solutionId: solutionId,
            tempChallageId: tempChallageId,
        };
        props.navigateToLetterView(navProps);



    };

    const [gridCurrentAction, setGridCurrentAction] = useState<LETTER_GRID_ACTION_TYPES | null>();

    //file id for download, edit & print
    const [fileId, setFileId] = useState('');
    const [uploadType, setUploadType] = useState<string>('All docs');

    //get file from file id
    const { data: downloadRes, isFetching: isDowndLoading } = props.getFile(fileId, uploadType);
    const [downloadedData, setDownloadedData] = useState<string>('');
    const [isDownloadedDataChanged, setIsDownloadedDataChanged] = useState<boolean>(false);
    const [timestamp, setTimestamp] = useState<string>('');

    //handle letter grid action
    const gridActionHandler = (rowData: IPatientResources, actionType: LETTER_GRID_ACTION_TYPES) => {
        setFileId('');
        setUploadType('');
        setGridCurrentAction(actionType);
        setFileId(rowData.resourceLink);
        setIsNewDoc(false);
        setFormConfig(false);
        switch (actionType) {
            case LETTER_GRID_ACTION_TYPES.EDIT:
                setFormConfig(true);
                setFormEditData(prevData => rowData.resourceLink === prevData.resourceLink ? prevData : rowData);
                setIsNewDoc(false);
                break;
            case LETTER_GRID_ACTION_TYPES.DOWNLOAD:
                setUploadType(rowData.uploadType);
                setTimestamp(new Date().getTime().toString());
                break;
            case LETTER_GRID_ACTION_TYPES.PRINT:
                setUploadType(rowData.uploadType);
                setTimestamp(new Date().getTime().toString());
                break;
            default:
                break;
        }
    };

    const [pdfDataUrl, setPdfDataUrl] = useState<string>('');

    useEffect(() => {

        try {
            if (downloadRes) {
                const signedUrl = downloadRes.data.signedUrl;
                if (viewSolutionPdf) {
                    setPdfDataUrl(signedUrl);
                } else {
                    setDownloadedData(signedUrl);
                    downloadedData !== signedUrl ? setIsDownloadedDataChanged(true) : setIsDownloadedDataChanged(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [downloadRes, formConfig, timestamp]);

    useEffect(
        () => {

            if (downloadedData && !isDowndLoading) {
                const data: IFormResoureseData = { ...formEditData, signedUrl: downloadedData };
                setFormEditData(data);
                if (gridCurrentAction == LETTER_GRID_ACTION_TYPES.DOWNLOAD) {
                    handleFileAction(downloadedData, formEditData?.resourceName ?? '', "download");
                }
                if (gridCurrentAction == LETTER_GRID_ACTION_TYPES.PRINT) {
                    handleFileAction(downloadedData, formEditData?.resourceName ?? '', "print");
                    // printDocument(downloadedData);
                }
            }
        },
        isDownloadedDataChanged ? [downloadedData] : [timestamp],
    );

    const ResourceActions = ({ rowData }: any) => {
        return (
            <Typhography component='div' additionalClasses='flex gap-6'>
                <Icon
                    iconType='PrinterIconMedium'
                    color='#A5B1BC'
                    onClick={() => gridActionHandler(rowData.original, LETTER_GRID_ACTION_TYPES.PRINT)}
                />
                <Icon
                    iconType='Download'
                    color='#A5B1BC'
                    onClick={() => gridActionHandler(rowData.original, LETTER_GRID_ACTION_TYPES.DOWNLOAD)}
                />
            </Typhography>
        );
    };

    const columns = useMemo<ColumnDef<IPatientResources>[]>(
        () => [
            {
                accessorKey: 'resourceName',
                id: 'resourceName',
                width: 100,
                header: 'Name',
                accessorFn: (row) => row.resourceName,
                cell: ({ row }) => {
                    return (
                        <div className='flex items-center gap-2 '>
                            <Typhography component='span' additionalClasses='break-words break-all whitespace-normal'>{row.original.resourceName}</Typhography>
                            {row.original.uploadType === 'Upload' && (
                                <div>
                                    <EditIcon
                                        classes='ml-1'
                                        onClick={() => {
                                            gridActionHandler(row.original, LETTER_GRID_ACTION_TYPES.EDIT);
                                        }}
                                        w={'16'}
                                        h={'16'}
                                    />
                                </div>
                            )}
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                accessorKey: 'uploadDate',
                header: 'Upload Date',
                footer: (props) => props.column.id,
                cell: ({ row }) => {
                    const mDate = moment(row.original.uploadDate);
                    const formattedDate = mDate.format('MM/DD/YYYY');
                    return formattedDate;
                },
            },
            {
                accessorKey: 'type',
                header: 'Type',
                footer: (props) => props.column.id,
                cell: ({ row }) => {
                    return (
                        <div className=''>
                            <Badge
                                badgeType={BADGETYPE.BUTTON}
                                colorVariant={row.original.uploadType === 'Upload' ? 'darkGreen' : 'darkBlue'}
                            >
                                {row.original.uploadType}
                            </Badge>
                        </div>
                    );
                },
            },
            {
                accessorKey: 'id',
                header: 'Action',
                footer: (props) => props.column.id,
                cell: ({ row }) => {
                    return <ResourceActions rowData={row} />;
                },
            },
        ],
        [searchText, gridCurrentAction],
    );

    const contentForNotStarted = (
        <>
            <Typhography
                component='div'
                additionalClasses='flex flex-col py-5 w-full items-center justify-center max-h-[calc(100vh-394px)] overflow-auto'

            >
                <Icon iconType='EditDocument' additionalClasses="pt-6 pb-6 myclass" />
                <Typhography
                    component='div'
                    additionalClasses='mb-6 text-16s font-semibold text-black-540 text-center w-2/3'
                >
                    This patient has not yet completed Social Determinants Screening
                </Typhography>
                <Typhography component='div' additionalClasses='grid grid-cols-6'>
                    <Typhography component='div' additionalClasses='col-start-2 col-span-4'>
                        <Button
                            additionalClasses='text-21s font-medium items-center bg-[#006CD0]'
                            buttonLabel='Start now'
                            data-testId='InputField-id'
                            iconAlignment='right'
                            iconType='ArrowBC'
                            isIconEnabled
                            variants='primaryBlue'
                            onClick={() => startScreeningProcess()}
                        />
                    </Typhography>
                </Typhography>
            </Typhography>
        </>
    );

    const navigationItemAction = (timeLineNavReq?: ISolutionvalues, type?: string) => {
        switch (type) {
            case TIMELINE_TYPES[4]:
                setSelectedVersionDate({
                    displayText: moment(timeLineNavReq?.surveyDate ?? '').format('MM/DD/YYYY'),
                    value: timeLineNavReq?.surveyDate ?? '',
                });
                setActiveTab(TabValue.SocialDeterminantsScreening);
                break;
            case TIMELINE_TYPES[1]:
                if (timeLineNavReq && timeLineNavReq?.challenge) {
                    const sReq = {
                        challengeId: timeLineNavReq?.challenge ?? '',
                        patientChallengeId: timeLineNavReq._id ?? '',
                    };
                    timeLineNavReq && setclickedId(timeLineNavReq?._id ?? '');
                    setActiveTab(TabValue.Challenges);
                    getSolutionData(sReq);
                }
                break;
        }

        if (
            timeLineNavReq &&
            timeLineNavReq?.variant?.pdfMetaData &&
            timeLineNavReq.variant.pdfMetaData?.resourceLink
        ) {
            setUploadType('Letter');
            setFileId(timeLineNavReq.variant.pdfMetaData?.resourceLink);
            setViewolutionPdf(true);
        }
    };

    const handleCloseModal = () => {
        setViewolutionPdf(false);
    };

    const handleCopyCurrentNote = (note: NoteProps) => {
        setCopiedNote(note);
    };


    return (
        <>
            <BlockNavigation isBlocked={startScreening} onNavigate={handleBlockedNavigation} />
            <Typhography component='div' additionalClasses='flex lg:flex-row md:flex-col sm:flex-col pt-8'>
                <Typhography component='div' additionalClasses='w-full max-w-full h-full'>
                    <div className='relative'>
                        {getLoadingState() && <LoadingProgress isLoading={getLoadingState()} />}
                    </div>
                    {error && <Message type={'danger'} text={error} onClose={() => setError("")} />}

                    <Typhography component='div' additionalClasses='flex flex-row p-3 space-x-4 py-0'>
                        <section className=' w-3/4  box-content'>
                            <Typhography component='div' additionalClasses='relative'>
                                <Typhography component='div' additionalClasses='absolute mt-2.5'>
                                    <Breadcrumb
                                        items={[
                                            {
                                                label: 'Patients',
                                                link: '/',
                                                navAction: () => navigate('/'),
                                            },
                                            {
                                                label: shortContent(
                                                    `${patient?.firstName || ''} ${patient?.lastName || ''}`,
                                                ),
                                            },
                                        ]}
                                        singleArrow={true}
                                        addtionalClasses='text-21s font-medium'
                                    />
                                </Typhography>

                                <TabList value={activeTab} handleClick={handleClick} additionalClasses='justify-end'>
                                    <Tab
                                        label='Social Determinants Screening'
                                        value={TabValue.SocialDeterminantsScreening}
                                        additionalClasses={`border-none ${activeTab === TabValue.SocialDeterminantsScreening && 'text-[#006CD0]'}`}
                                    />
                                    <Tab
                                        label='Challenges'
                                        value={TabValue.Challenges}
                                        count={challengeCount > 0 ? challengeCount?.toString() : null}
                                        additionalClasses={`border-none ${activeTab === TabValue.Challenges && 'text-[#006CD0]'}`}
                                    />
                                    <Tab
                                        label='Forms and Letters'
                                        value={TabValue.FormsAndLetters}
                                        additionalClasses={`border-none ${activeTab === TabValue.FormsAndLetters && 'text-[#006CD0]'}`}
                                    />
                                    {/* <Tab
                                        label='Outcomes'
                                        value={TabValue.Outcomes}
                                        additionalClasses={`border-none ${activeTab === TabValue.Outcomes && 'text-[#006CD0]'}`}
                                    /> */}
                                </TabList>
                                {/* Patient details section */}
                                <Typhography component='div' additionalClasses='flex flex-col mt-6'>
                                    <Typhography
                                        component='div'
                                        additionalClasses=' lg:mt-0 bg-white rounded-md border'
                                    >
                                        <Typhography component='div' additionalClasses=''>
                                            <Typhography
                                                component='div'
                                                additionalClasses='flex flex-row align-middle p-4 divide-x'
                                            >
                                                <Typhography component='div' additionalClasses='flex basis-1/2'>
                                                    <ImagePreview
                                                        imageUrl={patient?.imageSignedUrl}
                                                        w={'w-11'}
                                                        h={'h-11'}
                                                        text={getFristLettersFromString(
                                                            `${patient?.firstName.replace(
                                                                /\s/g,
                                                                '',
                                                            )} ${patient?.lastName.replace(/\s/g, '')}`,
                                                        )}
                                                        isSmall={false}
                                                        bgColor={bgColorRandom}
                                                        textClasses={'text-36s'}
                                                    />
                                                    <Typhography
                                                        component='div'
                                                        additionalClasses='flex-5 flex-col md:ml-0 lg:ml-8 sm:ml-0'
                                                    >
                                                        <Typhography
                                                            component='div'
                                                            additionalClasses='flex divide-x gap-2 items-center'
                                                        >
                                                            <Typhography additionalClasses='text-12s font-semibold text-black-540'>
                                                                {`${patient?.firstName || ''} ${patient?.lastName || ''}`}
                                                            </Typhography>

                                                            <Typhography
                                                                component='span'
                                                                additionalClasses='flex text-nowrap text-12s font-normal text-grey-540 pl-2'
                                                            >
                                                                {`${patient?.dob ? moment().diff(moment(patient?.dob), 'years') : 0} years old`}
                                                            </Typhography>
                                                            {!patient?.patientConsent || patient?.patientConsent?.consentStatus === 'NO CONSENT' && <Typhography>
                                                                <Typhography component='span' additionalClasses='flex ml-1 text-nowrap text-[10px] font-medium text-white bg-red-500 rounded-full px-[8px] py-[4px]'>
                                                                    No consent
                                                                </Typhography>
                                                            </Typhography>}
                                                            <Typhography
                                                                additionalClasses='flex text-12s font-normal text-grey-540 cursor-pointer'
                                                                onClick={rowIconClick}
                                                            >
                                                                <EditIcon classes='mx-1' w={'16'} h={'16'} />
                                                                {'Edit patient'}
                                                            </Typhography>
                                                        </Typhography>
                                                        <Typhography
                                                            component='div'
                                                            additionalClasses='flex text-21s font-normal text-black-540 gap-2 mt-4'
                                                        >
                                                            <Typhography
                                                                component='div'
                                                                additionalClasses='flex text-nowrap text-12s'
                                                            >
                                                                <EmailIcon classes='mr-1 mt-0.5' w={'12'} h={'12'} />
                                                                {patient?.email || ''}
                                                            </Typhography>

                                                            <Typhography
                                                                component='div'
                                                                additionalClasses=' flex text-nowrap text-12s'
                                                            >
                                                                <PhoneIcon classes='mr-1 mt-0.5' w={'12'} h={'12'} />
                                                                {patient?.phoneNumber || ''}
                                                            </Typhography>
                                                            <Typhography
                                                                component='div'
                                                                additionalClasses=' flex text-nowrap text-12s'
                                                            >
                                                                <WorldIcon classes='mr-1 mt-0.5' w={'12'} h={'12'} />
                                                                {patient?.primaryLanguage || 'English'}
                                                            </Typhography>
                                                        </Typhography>
                                                    </Typhography>
                                                </Typhography>

                                                <Typhography component='div' additionalClasses='basis-1/2'>
                                                    {patient?.externalCaseManagers.length &&
                                                        patient.externalCaseManagers.length > 0 &&
                                                        !(
                                                            patient.externalCaseManagers.length === 1 &&
                                                            patient.externalCaseManagers[0]?.fullName === ''
                                                        ) ? (
                                                        <Typhography component='div'>
                                                            <Typhography component='div' additionalClasses='pb-4 px-4'>
                                                                <Typhography
                                                                    component='div'
                                                                    additionalClasses='text-12s font-medium text-black-540'
                                                                >
                                                                    {'External Case Manager(s)'}
                                                                </Typhography>
                                                            </Typhography>

                                                            <Typhography
                                                                component='div'
                                                                additionalClasses='flex flex-row gap-2 flex-wrap px-4'
                                                            >
                                                                {patient?.externalCaseManagers
                                                                    .filter((value) => value.fullName !== '')
                                                                    .map((manager, index) => (
                                                                        <Typhography
                                                                            key={index} // Make sure to include a unique key for each item in the loop
                                                                            component='div'
                                                                            additionalClasses={`text-12s font-normal bg-[#F1F5F9] py-1 rounded-[100px] p-2 flex min-w-20 `}
                                                                        >
                                                                            <UserIcon
                                                                                classes='mr-1 mt-0.5'
                                                                                w={'12'}
                                                                                h={'12'}
                                                                            />
                                                                            {manager.fullName}
                                                                            <TooltipWithChildren
                                                                                tooltipHeading={`${manager.fullName} (details)`}
                                                                                additionalClasses='ml-1 mt-0.5'
                                                                                tooltipContent={
                                                                                    <>
                                                                                        <Typhography
                                                                                            component='div'
                                                                                            additionalClasses='flex mt-2 cursor-pointer'
                                                                                        >
                                                                                            <Typhography
                                                                                                component='div'
                                                                                                additionalClasses='flex flex-row sm:flex-row lg:flex-row md:flex-row text-gray-700'
                                                                                            >
                                                                                                {manager?.email && (
                                                                                                    <Typhography
                                                                                                        component='div'
                                                                                                        additionalClasses='flex text-21s font-normal text-nowrap'
                                                                                                    >
                                                                                                        <EmailIcon classes='mr-1' />
                                                                                                        {manager.email}
                                                                                                    </Typhography>
                                                                                                )}
                                                                                                {manager?.phoneNumber && (
                                                                                                    <Typhography
                                                                                                        component='div'
                                                                                                        additionalClasses='ml-2 flex text-21s font-normal text-nowrap'
                                                                                                    >
                                                                                                        <PhoneIcon classes='mr-1' />
                                                                                                        {
                                                                                                            manager?.phoneNumber
                                                                                                        }
                                                                                                    </Typhography>
                                                                                                )}
                                                                                                {manager?.agency && (
                                                                                                    <Typhography
                                                                                                        component='div'
                                                                                                        additionalClasses='ml-2 flex text-21s font-normal text-nowrap'
                                                                                                    >
                                                                                                        <BuildingIcon classes='mr-1' />
                                                                                                        {
                                                                                                            manager?.agency
                                                                                                        }
                                                                                                    </Typhography>
                                                                                                )}
                                                                                            </Typhography>
                                                                                        </Typhography>
                                                                                    </>
                                                                                }
                                                                                tooltipIcon='info'
                                                                                tooltipContentHighlighted={true}
                                                                                selectTooltipContent={true}
                                                                            >
                                                                                <Icon iconType={'Info'} color='blue' />
                                                                            </TooltipWithChildren>
                                                                        </Typhography>
                                                                    ))}
                                                            </Typhography>
                                                        </Typhography>
                                                    ) : (
                                                        <Typhography
                                                            component='div'
                                                            additionalClasses='flex flex-col items-center p-0 text-21s font-medium'
                                                        >
                                                            <InfoIcon40 w='24' h='24' />
                                                            <Typhography
                                                                component='div'
                                                                additionalClasses='text-center mt-3'
                                                            >
                                                                This client is not currently engaged in community-based
                                                                case management
                                                            </Typhography>
                                                        </Typhography>
                                                    )}
                                                </Typhography>
                                            </Typhography>
                                        </Typhography>
                                    </Typhography>
                                </Typhography>
                                <TabPanel value={activeTab} index='1' isCenter={false}>
                                    <Typhography component='div' additionalClasses='flex flex-col'>
                                        {!startScreening ? (
                                            <PanelCard
                                                additionalClasses='mt-8 h-[calc(100vh-316px)]'
                                                headerTitle='Social Determinants Screening'
                                                completedDateTime={
                                                    patientSchema?.completedOn
                                                        ? moment(patientSchema.completedOn).format('MM/DD/YYYY')
                                                        : ''
                                                }
                                                enableBack={selectedVersionDate.value !== ''}
                                                backButtonClick={backFromVersions}
                                                controllers={controllers}
                                                // isLoading={!patientSchema || versionIsLoading}
                                                content={
                                                    viewOnlyMode ? (
                                                        <PatientScreener
                                                            {...props}
                                                            viewMode={true}
                                                            goToScreenerEdit={() => {
                                                                setStartScreening(true);
                                                            }}
                                                            selectedVersion={selectedVersion}
                                                            selectedPatient={patient}
                                                            patientSchema={patientSchema}
                                                            saveScreenerNotes={saveServeyNote}
                                                            patientSchemaRefetch={props.patientSchemaRefetch}
                                                        ></PatientScreener>
                                                    ) : (
                                                        patientSchema && contentForNotStarted
                                                    )
                                                }
                                                contentClasses={
                                                    viewOnlyMode
                                                        ? 'grid grid-cols-1'
                                                        : 'grid grid-cols-1 place-items-center'
                                                }
                                            />
                                        ) : (
                                            <PatientScreener
                                                {...props}
                                                viewMode={false}
                                                isEditable={isEditable}
                                                saveAndExit={saveAndExit}
                                                selectedPatient={patient}
                                                getChallengeData={getChallengeData}
                                                patientSchema={patientSchema}
                                                saveScreenerNotes={saveServeyNote}
                                                patientSchemaRefetch={props.patientSchemaRefetch}
                                            ></PatientScreener>
                                        )}
                                    </Typhography>
                                </TabPanel>
                                <TabPanel value={activeTab} index='2'>
                                    <Typhography component='div' additionalClasses='flex flex-col w-full'>
                                        {challengeData && (
                                            <ChallengePannel
                                                challengeData={challengeData}
                                                makeUrgent={makeUrgent}
                                                sortChallenges={sortChallenges}
                                                saveSolutionNote={saveSolutionNote}
                                                setShowNote={setShowNote}
                                                showNote={showNote}
                                                showSolutionLongDescription={showSolutionLongDescription}
                                                setShowSolutionLongDescription={setShowSolutionLongDescription}
                                                getSolution={getSolutionData}
                                                solution={solution}
                                                cancelAction={cancelAction}
                                                setActionData={setActionData}
                                                actionData={actionData}
                                                setSolutionRowClick={setSolutionRowClick}
                                                solutionRowClick={solutionRowClick}
                                                navigateToLetterView={navigateLetter}
                                                setclickedId={setclickedId}
                                                clickedId={clickedId}
                                                updateChallenge={updateChallenge}
                                                setShowChallengeTimeline={setShowChallengeTimeline}
                                                setSelectedChallenge={setSelectedChallenge}
                                                copiedNote={copiedNote}
                                                setRowDetails={setRowDetails}
                                            />
                                        )}
                                    </Typhography>
                                </TabPanel>
                                <TabPanel value={activeTab} index='3'>
                                    <PanelCard
                                        additionalClasses='mt-8 !border-none !border-b  h-[calc(100vh-316px)] overflow-hidden'
                                        headerTitle='Forms and Letters'
                                        enableBack={selectedVersionDate.value !== ''}
                                        backButtonClick={backFromVersions}
                                        controllers={controllersFormLetter}
                                        wrapperClass='border-b-0 !px-0'
                                        content={
                                            <div className='overflow-hidden overflow-y-auto crollbar-custom'>
                                                {formData && (
                                                    <Table
                                                        {...{
                                                            data: patientResourceData,
                                                            columns,
                                                            sorting,
                                                            onSortingChange,
                                                            pagination,
                                                            totalRecords,
                                                            isManualPagination,
                                                            isManualSorting,
                                                            onPaginationChange,
                                                            containerHeightRemoveInPixel: 388,
                                                            rowClick,
                                                            key: key,
                                                            setRowSelection,
                                                            isLoading: false,
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        }
                                    />
                                </TabPanel>
                                {/* <TabPanel value={activeTab} index='4'>
                                    <Typhography component='div' additionalClasses='flex flex-col mt-2'>
                                        Outcomes
                                    </Typhography>
                                </TabPanel> */}
                            </Typhography>
                        </section>
                        {showChallengeTimeline ? (
                            <ChallengeTimeline
                                patientId={patientId ?? ''}
                                challengeId={selectedChallenge ?? ''}
                                getPatientChallengeTimeLine={props.getPatientChallengeTimeLine}
                                setShowChallengeTimeline={setShowChallengeTimeline}
                                handleCopyCurrentNote={handleCopyCurrentNote}
                                rowDetails={rowDetails}
                            />
                        ) : (
                            <TimelineAndHistory
                                timeLineData={timeLineData}
                                saveServeyNotes={saveServeyNote}
                                navigationItemAction={navigationItemAction}
                                getNoteHistory={props.getNoteHistory}
                                toggleTodoCheckbox={toggleTimelineTodoCheckbox}
                            />
                        )}
                    </Typhography>
                </Typhography>
                {/* {showNotification && (
                    <GridNotification
                        handleCancel={() => setShowNotification(false)}
                        handleUndo={() => {
                            setSolutionRowClick(true);
                            setShowNotification(false);
                        }}
                        headerTitle={`${actionData?.solutionName} deleted`}
                        description=''
                        isBasic
                    />
                )} */}
                {formConfig && (
                    <FormUploadModal
                        rowData={patient}
                        isOpenModal={formConfig}
                        handleCancelButton={() => setFormConfig(false)}
                        handleConfirmButton={savePatientResource}
                        editData={formEditData}
                        isNewDoc={isNewDoc}
                        setIsNewDoc={setIsNewDoc}
                        resourceTypes={resourceTypes}
                        loading={props.isLoading}
                    />
                )}
                {viewSolutionPdf && pdfDataUrl && (
                    <PdfPreviewModal
                        signedUrl={pdfDataUrl}
                        isOpenModal={viewSolutionPdf}
                        handleCancelButton={handleCloseModal}
                    />
                )}
            </Typhography>

            {confirmNavigate && <DialogBox
                title='Confirmation!'
                content="You have unsaved changes. If you leave this page, your answers will be discarded. To save your data, click 'Save and Exit.'"
                isConfirmButtonEnabled={true}
                isCancelButtonEnabled={true}
                handleConfirmButton={() => {
                    navigateDialogProps?.handleConfirm();
                }}
                handleCancelButton={navigateDialogProps?.handleCancel}
                handleOuterClick={navigateDialogProps?.handleCancel}
                textConfirmButton={'Discard changes'}
                textCancelButton={'Stay on page'}
                dialogBoxState={confirmNavigate}
                confirmBtnAdditionalClasses="mx-2 px-4 mt-4"
                cancelBtnAdditionalClasses="mx-2 px-4 mt-4"
            />}
        </>
    );
};
const PatientViewComponent = patientHOC(PatientDetail);
export default PatientViewComponent;
