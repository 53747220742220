import {
    BADGETYPE,
    Badge,
    BorderType,
    Button,
    CheckBoxComponent,
    Label,
    ListRow,
    NoteEditor,
    NoteItemProps,
    NoteProps,
    RegularDropdown,
    Typhography,
} from 'dfh-ui-library';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { language } from '../helpers/language';
import {
    ISaveChallengeNotesRequest,
    ISolution,
    ISolutionNoteRequest,
    IVariation,
    solutionTypes,
} from 'utils/types/patient.type';
import { ActionTypes, CHALLENGE_STATUS } from 'utils/enum';
import { EmailIcon, PhoneIcon } from '../helpers/iconHelper';
import User from 'utils/types/user.type';
import { useSelector } from 'react-redux';
import { getPdf } from 'stores/pdfSlice';
import { RootState } from 'stores/store';
import { formatDate } from 'utils/helpers';
const RowDetails: React.FC<{
    item: any;
    challengeData: any;
    showNote: boolean;
    setShowNote?: (status: boolean) => void;
    showSolutionLongDescription?: boolean;
    setShowSolutionLongDescription?: (status: boolean) => void;
    rowClick?: boolean;
    updateChallenge?: (req: ISaveChallengeNotesRequest) => void;
    saveSolutionNote?: (req: ISolutionNoteRequest) => void;
    solution?: ISolution[] | undefined;
    referalAction?: (solution: ISolution) => void;
    letterAction?: (solution: ISolution) => void;
    formAction?: (solution: ISolution) => void;
    solutionAction?: (solution: ISolution) => void;
    actionType?: ActionTypes;
    actionData?: ISolution;
    cancelAction?: () => void;
    setRowClick?: (isRowClick: boolean) => void;
    navigateToLetterView?: (
        challengeId: string,
        veriantId: string,
        varient: string,
        solutionId: string,
        tempChallageId: string,
    ) => void;
    showChallengeTimeline?: (status: boolean) => void;
    copiedNote?: NoteProps;
    showNoteEdit?: boolean;
    setShowNoteEdit?: (value: boolean) => void;
}> = ({
    item,
    challengeData,
    showNote,
    setShowNote,
    showSolutionLongDescription,
    rowClick,
    updateChallenge,
    saveSolutionNote,
    solution,
    referalAction,
    letterAction,
    formAction,
    actionType,
    actionData,
    cancelAction,
    setRowClick,
    navigateToLetterView,
    showChallengeTimeline,
    copiedNote,
    showNoteEdit,
    setShowNoteEdit,
}) => {
        const [selectedValuesReferral, setSelectedValuesReferral] = useState<{
            displayText: string;
            value: string;
        }>({
            displayText: '',
            value: '',
        });
        const [selectedValuesLetter, setSelectedValuesLetter] = useState<{
            displayText: string;
            value: string;
        }>({
            displayText: '',
            value: '',
        });
        const [selectedValuesForm, setSelectedValuesForm] = useState<{
            displayText: string;
            value: string;
        }>({
            displayText: '',
            value: '',
        });

        const defaultItem = {
            note: [{ text: '', enableCheckbox: false, isChecked: false }],
            _id: '',
        };

        const [savedItem, setSavedItem] = useState<NoteProps>(defaultItem);
        const [selectedSolutionVariant, setSelectedSolutionVariant] = useState<string>('');
        const [selectedSolution, setSelectedSolution] = useState<ISolution>();
        const userJson = localStorage.getItem('user');
        const userDetails: User = userJson ? JSON.parse(userJson) : null;
        const rowDetailsComponentRef = useRef<null | HTMLDivElement>(null);
        const pdfUrl = useSelector((state: RootState) => getPdf(state));

        useEffect(() => {
            rowDetailsComponentRef && rowDetailsComponentRef.current?.scrollIntoView(true);
        }, [rowClick]);

        useEffect(() => {
            const updatedNote = challengeData?.find((challenge: any) => challenge._id === item?._id)?.note;
            setSavedItem(updatedNote);
        }, [challengeData]);

        useEffect(() => {
            if (copiedNote) {
                setSavedItem(copiedNote);
            } else if (item.note) {
                setSavedItem(item.note);
            } else {
                setSavedItem(defaultItem);
            }
        }, [item, copiedNote]);

        const handleSelectedValuesChange = (newValues: { displayText: string; value: string }, solution?: ISolution) => {
            const foundRecord =
                solution && solution.variants ? solution.variants.find((item) => item._id === newValues.value) : null;

            const newSolution = {
                ...solution,
                variants: foundRecord ? [foundRecord] : [],
            };

            switch (solution?.solutionType) {
                case 'Referral': {
                    referalAction && referalAction(newSolution);
                    setSelectedValuesReferral(newValues);
                    break;
                }
                case 'Letter': {
                    setSelectedValuesLetter(newValues);
                    setSelectedSolution(newSolution);
                    setSelectedSolutionVariant(newValues.displayText);
                    letterAction && letterAction(newSolution);
                    // letterBtnClick(newSolution, newValues.displayText);
                    break;
                }
                case 'Form': {
                    setSelectedValuesForm(newValues);
                    setSelectedSolution(newSolution);
                    setSelectedSolutionVariant(newValues.displayText);
                    formAction && formAction(newSolution);
                    // formBtnClick(newSolution, newValues.displayText);
                    break;
                }
                default:
                    return null;
            }
        };

        const solutionNote = (items: NoteItemProps[], _id: string) => {
            if (actionData?._id && actionData.variants)
                saveSolutionNote &&
                    saveSolutionNote({
                        solutionId: actionData._id,
                        solutionVariantId: actionData.variants[0]._id || '',
                        note: items,
                        patient: '',
                        patientChallenge: '',
                    });
        };

        const isNoteSaved = useRef<boolean>(false);

        const handleAddChallengeNote = useCallback(async (
            _items: NoteProps,
            patientChallengeId: string,
            isResolved: boolean = false,
        ) => {

            if (isNoteSaved.current) return;
            isNoteSaved.current = true;
            if (updateChallenge && _items.note && patientChallengeId) {
                await updateChallenge({
                    id: patientChallengeId,
                    note: _items,
                    actionedUser: userDetails._id,
                    status: isResolved ? CHALLENGE_STATUS.RESOLVED : item.status,
                });
                isNoteSaved.current = false;
            }
        }, [userDetails._id]);

        const controllersForm = (solution: ISolution) => (
            <>
                {solution && solution?.variants && solution?.variants?.length > 1 ? (
                    <RegularDropdown
                        bgClasses='bg-blue-550 text-white'
                        defaultText='Letter'
                        handleSelectedValuesChange={(newValue: any) => handleSelectedValuesChange(newValue, solution)}
                        hoverBgColor='bg-sky-700'
                        options={getDropdownObject(solution?.variants)}
                        selectedValues={selectedValuesForm}
                        additionalClasses='item-center'
                    />
                ) : (
                    <Button
                        additionalClasses='text-21s font-normal items-center !w-auto'
                        buttonLabel='Fill out'
                        data-testId='InputField-id'
                        variants='smallBlue'
                        // onClick={() => formBtnClick(solution, '')}
                        onClick={() => {
                            setSelectedSolution(solution);
                            setSelectedSolutionVariant('');
                            formAction && formAction(solution);
                        }}
                    />
                )}
            </>
        );

        const letterBtnClick = (solution: ISolution, varient: string) => {
            if (solution && solution?.variants && solution?.variants[0]) {
                navigateToLetterView &&
                    navigateToLetterView(
                        item._id,
                        solution?.variants[0]?._id ?? '',
                        varient ?? solution?.variants[0].solutionVariantName,
                        solution?._id ?? '',
                        item.challenge._id,
                    );
            }
        };

        const formBtnClick = (solution: ISolution, varient: string) => {
            if (solution && solution?.variants && solution?.variants[0]) {
                navigateToLetterView &&
                    navigateToLetterView(
                        item._id,
                        solution?.variants[0]?._id ?? '',
                        varient ?? solution?.variants[0].solutionVariantName,
                        solution?._id ?? '',
                        item.challenge._id,
                    );
            }
        };

        const controllersLetter = (solution: ISolution) => {
            return (
                <>
                    {solution && solution?.variants && solution?.variants?.length > 1 ? (
                        <RegularDropdown
                            bgClasses='bg-blue-550 text-white'
                            defaultText='Letter'
                            handleSelectedValuesChange={(newValue: any) => handleSelectedValuesChange(newValue, solution)}
                            hoverBgColor='bg-sky-700'
                            options={getDropdownObject(solution?.variants)}
                            selectedValues={selectedValuesLetter}
                            additionalClasses='item-center'
                        />
                    ) : (
                        <Button
                            additionalClasses='text-21s font-normal items-center !w-auto'
                            buttonLabel='Letter'
                            data-testId='InputField-id'
                            variants='smallBlue'
                            // onClick={() => letterBtnClick(solution, '')}
                            onClick={() => {
                                setSelectedSolution(solution);
                                setSelectedSolutionVariant('');
                                letterAction && letterAction(solution);
                            }}
                        />
                    )}
                </>
            );
        };

        const getDropdownObject = (variants: IVariation[]) => {
            const transformedArray = variants.map(({ _id, solutionVariantName }) => ({
                displayText: solutionVariantName ?? '',
                value: _id ?? '',
            }));
            return transformedArray;
        };

        const controllersReferal = (solution: ISolution) => (
            <>
                {solution && solution?.variants && solution?.variants?.length > 1 ? (
                    <RegularDropdown
                        bgClasses='bg-blue-550 text-white'
                        defaultText='Referral'
                        handleSelectedValuesChange={(newValue: any) => handleSelectedValuesChange(newValue, solution)}
                        hoverBgColor='bg-sky-700'
                        options={getDropdownObject(solution?.variants)}
                        selectedValues={selectedValuesReferral}
                        additionalClasses='item-center'
                    />
                ) : (
                    <Button
                        additionalClasses='text-21s font-normal items-center !w-auto !min-w-[120px]'
                        buttonLabel='Referral'
                        data-testId='InputField-id'
                        variants='smallBlue'
                        onClick={() => referalAction && referalAction(solution)}
                    />
                )}
                <></>
            </>
        );

        // Function to get controller based on solution type
        const getControllerByType = (solution: ISolution, solutionType: solutionTypes): React.ReactNode => {
            switch (solutionType) {
                case 'Referral':
                    return controllersReferal(solution);
                case 'Form':
                    return controllersForm(solution);
                case 'Letter':
                    return controllersLetter(solution);
                default:
                    return null; // Handle other cases or return a default controller
            }
        };

        const solutionContents = (solutions: ISolution[]) => (
            <>
                {solutions
                    .filter((solution) => solution.variants && solution.variants.length > 0)
                    .map((solution, index) => (
                        <Typhography
                            component='div'
                            additionalClasses='flex my-3.5 mx-6 bg-[#F9FAFB] !w-auto items-center p-4 rounded-lg justify-between'
                            key={`${index}-id`}
                        >
                            <Typhography component='div' additionalClasses='flex-col'>
                                <Typhography component='div' additionalClasses='!text-25s'>
                                    {solution.solutionName || ''}
                                </Typhography>
                                <Typhography
                                    component='div'
                                    additionalClasses='text-14 font-normal text-black-900  text-21s text-grey-540 font-normal mt-1'
                                >
                                    {solution.shortDescription || ''}
                                </Typhography>
                            </Typhography>
                            <Typhography component='div' additionalClasses={`flex justify-end }`}>
                                {getControllerByType(solution, solution.solutionType)}
                            </Typhography>
                        </Typhography>
                    ))}
            </>
        );

        const solutionNoteContent = (patientChallengeId: any) => (
            <Typhography component='div' additionalClasses='justify-between align-middle pr-4'>
                {!showNoteEdit ? (
                    <>
                        <Typhography component='div'>
                            {solutionHeader(item.patientSolutions, item.challenge, solution?.length)}
                        </Typhography>
                        <Typhography
                            component='div'
                            additionalClasses='rounded border border-gray-19 p-3 ml-4 min-h-[150px] cursor-pointer'
                            onClick={() => {
                                setShowNoteEdit && setShowNoteEdit(true);
                            }}
                        >
                            <Typhography
                                component='div'
                                additionalClasses='flex justify-between items-center pb-3 border-b'
                            >
                                <Typhography component='div' additionalClasses='text-grey-540 text-12s'>
                                    {savedItem && savedItem?.actionedUser?.fullName && savedItem?.date
                                        ? `Edited ${formatDate(savedItem.date)} by ${savedItem.actionedUser.fullName}`
                                        : ''}
                                </Typhography>
                                <Typhography component='div' additionalClasses='flex items-center'>
                                    <Badge
                                        additionalClasses='text-12s text-black-540 bg-primaryBlue text-nowrap !mr-0'
                                        badgeType={BADGETYPE.PILLS}
                                        colorVariant='gray'
                                    >
                                        Resolved
                                    </Badge>
                                </Typhography>
                            </Typhography>
                            <Typhography component='div'>
                                {savedItem?.note &&
                                    savedItem.note.length > 0 &&
                                    savedItem.note?.map((item, index) => (
                                        <Typhography component='div' key={index} additionalClasses='text-12s pt-2'>
                                            {item && item?.enableCheckbox && (
                                                <>
                                                    <CheckBoxComponent
                                                        onChange={() => { }}
                                                        checked={item.isChecked}
                                                        noteId={item.noteId}
                                                        noteIndex={item.noteIndex}
                                                    />
                                                    <Label
                                                        label={item?.text}
                                                        name='testname'
                                                        additionalClasses={`!text-12s ${item?.isChecked && 'line-through text-gray-95'} p-2`}
                                                    />
                                                </>
                                            )}
                                            {!item?.enableCheckbox && (
                                                <Typhography component='p' additionalClasses='text-12s font-normal'>
                                                    {item.text}
                                                </Typhography>
                                            )}
                                        </Typhography>
                                    ))}
                                {savedItem?.note && savedItem?.note?.length === 1 && savedItem?.note[0]?.text == '' && (
                                    <Typhography component='p' additionalClasses='text-12s font-normal text-grey-540'>
                                        {'Add new note here...'}
                                    </Typhography>
                                )}
                            </Typhography>
                        </Typhography>
                    </>
                ) : (
                    <Typhography component='div' additionalClasses='flex flex-col h-[calc(100vh-390px)] relative'>
                        {solutionHeader(item.patientSolutions, item.challenge, solution?.length)}
                        <NoteEditor
                            onAdd={(items: any) => handleAddChallengeNote(items, patientChallengeId)}
                            cancelAction={() => setShowNoteEdit && setShowNoteEdit(false)}
                            savedItems={savedItem}
                            showNote={showNote}
                            additionalClassesNote='h-full pl-4 overflow-y-auto'
                            additionalClassesButtons='pl-4 pb-4'
                            additionalClassesTitlebar='pl-4'
                            isChallengeNote={true}
                            isAutoSave={true}
                        />
                    </Typhography>
                )}
            </Typhography>
        );

        const solutionHeader = (_solutionDetails: any, challenge: any, solutionLength: number = 0) => (
            <>
                <Typhography component='div'>
                    <Typhography additionalClasses='flex justify-between w-full pl-4 pt-4 pb-2'>
                        <Typhography component='span' additionalClasses='flex '>
                            <Typhography component='div' additionalClasses='font-semibold text-25s inline-block'>
                                <Typhography component='span' additionalClasses='mr-3'>
                                    {challenge.challengeName}
                                </Typhography>
                                <Typhography
                                    component='span'
                                    onClick={() => showChallengeTimeline && showChallengeTimeline(true)}
                                    additionalClasses='whitespace-nowrap font-semibold text-12s underline cursor-pointer mr-3'
                                >
                                    {'View previous'}
                                </Typhography>
                            </Typhography>
                        </Typhography>
                        <Typhography component='div' additionalClasses='flex items-start'>
                            <Badge
                                additionalClasses='text-12s text-black-540 bg-primaryBlue text-nowrap !mr-0'
                                badgeType={BADGETYPE.PILLS}
                                colorVariant='gray'
                            >
                                {solutionLength} {language.solution.badgeTitle}
                            </Badge>
                        </Typhography>
                    </Typhography>
                    <Typhography component='div' additionalClasses='flex px-4 text-21s font-normal text-grey-540 mb-4'>
                        {challenge.challengeDescription}
                    </Typhography>
                </Typhography>
            </>
        );

        const solutionHeaderResolved = (_solution: any, challenge: any) => (
            <>
                <ListRow
                    title={
                        <Typhography component='div'>
                            <Badge
                                additionalClasses='text-12s text-black-540 bg-primaryBlue text-nowrap'
                                badgeType={BADGETYPE.PILLS}
                                colorVariant='gray'
                            >
                                {language.solution.challengeResolved}
                            </Badge>

                            <Typhography component='div' additionalClasses='font-semibold text-25s mt-2'>
                                {challenge.challengeName}
                            </Typhography>
                        </Typhography>
                    }
                    additionalClasses='py-3.5 px-6'
                    bottomBorderType={BorderType.Dashed}
                    controllers={<div className='text-nowrap'></div>}
                    bottomContent={
                        <Typhography component='div' additionalClasses='flex px-6 text-21s font-normal text-grey-540'>
                            {challenge.challengeDescription}
                        </Typhography>
                    }
                />
            </>
        );

        const solutionHeaderRefer = (_actionData: any, challenge: any) => (
            <>
                <ListRow
                    title={
                        <Typhography component='div'>
                            <Badge
                                additionalClasses='text-12s text-black-540 bg-primaryBlue'
                                badgeType={BADGETYPE.PILLS}
                                colorVariant='gray'
                            >
                                {(_actionData?.solutionType === 'Letter' || _actionData?.solutionType === 'Form') &&
                                    showSolutionLongDescription &&
                                    language?.solution?.challengeLetterStart}
                                {(_actionData?.solutionType === 'Letter' || _actionData?.solutionType === 'Form') &&
                                    !showSolutionLongDescription &&
                                    showNote &&
                                    language?.solution?.challengeLetterComplete}
                                {_actionData?.solutionType === 'Referral' && language?.solution?.challengeRefer}
                            </Badge>

                            <Typhography component='div' additionalClasses='font-semibold text-25s mt-2 text-nowrap'>
                                {challenge?.challengeName}
                            </Typhography>
                        </Typhography>
                    }
                    additionalClasses='py-3.5 px-6'
                    bottomBorderType={BorderType.Dashed}
                    controllers={<div className='text-nowrap'></div>}
                    bottomContent={
                        <Typhography component='div' additionalClasses='flex px-6 text-21s font-normal text-grey-540'>
                            {challenge?.challengeDescription}
                        </Typhography>
                    }
                />
            </>
        );

        const getVariationData = (variations: IVariation[]): React.ReactNode => {
            if (variations.length == 1) {
                const variFirst = variations[0];
                return (
                    <Typhography component='div' additionalClasses='flex gap-4'>
                        <Typhography component='div' additionalClasses='flex text-nowrap text-21s font-medium items-center'>
                            <EmailIcon classes='mr-1 mt-0.5' w={'24'} h={'24'} />
                            {variFirst?.referralData?.email}
                        </Typhography>

                        <Typhography
                            component='div'
                            additionalClasses=' flex text-nowrap text-21s font-medium items-center'
                        >
                            <PhoneIcon classes='mr-1 mt-0.5' w={'24'} h={'24'} />
                            {variFirst?.referralData?.phone}
                        </Typhography>
                    </Typhography>
                );
            } else {
                return <></>;
            }
        };

        const solutionStartContent = (actionData?: ISolution): React.ReactNode => {
            return (
                <>
                    {actionData && (
                        <ListRow
                            title={
                                <Typhography component='div' additionalClasses='font-semibold text-25s mt-2 text-nowrap'>
                                    {actionData.solutionName}
                                </Typhography>
                            }
                            subTitle={''}
                            additionalClasses='mb-2'
                            bottomBorderType={BorderType.Dashed}
                            controllers={''}
                            bottomContent={
                                <Typhography component='div' additionalClasses='pb-4'>
                                    <Typhography
                                        component='div'
                                        additionalClasses='flex text-21s font-normal text-grey-540 col-span-6 pb-4'
                                    >
                                        {actionData.longDescription}
                                    </Typhography>
                                </Typhography>
                            }
                        />
                    )}
                </>
            );
        };

        const solutionCompleteContent = (actionData?: ISolution): React.ReactNode => {
            return (
                <>
                    {actionData && (
                        <ListRow
                            title={
                                <Typhography component='div' additionalClasses='font-semibold text-25s mt-2 text-nowrap'>
                                    {actionData.solutionName}
                                </Typhography>
                            }
                            subTitle={''}
                            additionalClasses='mb-2'
                            bottomBorderType={BorderType.Dashed}
                            controllers={''}
                            bottomContent={
                                <Typhography component='div' additionalClasses='pb-4'>
                                    <Typhography
                                        component='div'
                                        additionalClasses='flex text-21s font-normal text-grey-540 col-span-6 pb-4'
                                    >
                                        {actionData.longDescription}
                                    </Typhography>

                                    <Typhography component='div' additionalClasses='mb-5'>
                                        {actionData?.variants && getVariationData(actionData?.variants)}
                                    </Typhography>

                                    {actionData.solutionType !== 'Referral' && (
                                        <Typhography component='div' additionalClasses='flex flex-col'>
                                            <Typhography additionalClasses='text-12s font-medium'>Letter</Typhography>
                                            <Typhography>
                                                <Badge
                                                    additionalClasses='text-12s text-black-540 bg-primaryBlue cursor-pointer'
                                                    badgeType={BADGETYPE.PILLS}
                                                    colorVariant='gray'
                                                >
                                                    <a href={pdfUrl} target='_blank'>
                                                        Healthcare Provider Letter Template 👁
                                                    </a>
                                                </Badge>
                                            </Typhography>
                                        </Typhography>
                                    )}
                                </Typhography>
                            }
                        />
                    )}
                </>
            );
        };

        const renderContent = () => {
            switch (actionType) {
                case ActionTypes.REFER:
                    return (
                        <>
                            {!rowClick && solutionHeaderRefer(actionData, item.challenge)}
                            {!rowClick && (
                                <Typhography
                                    component='div'
                                    additionalClasses='my-3.5 mx-6 bg-[#F9FAFB] !w-auto items-center p-4 rounded-lg'
                                >
                                    {solutionCompleteContent(actionData)}

                                    {showNote && (
                                        <>
                                            <Typhography additionalClasses='text-12s font-medium'>Notes</Typhography>
                                            <NoteEditor
                                                onAdd={(items: any) => saveSolutionNote && solutionNote(items, item._id)}
                                                cancelAction={cancelAction}
                                                buttonLabels={{ cancel: 'Cancel', save: 'Complete' }}
                                            />
                                        </>
                                    )}
                                </Typhography>
                            )}
                        </>
                    );
                case ActionTypes.LETTER: {
                    if (!rowClick && showSolutionLongDescription) {
                        return (
                            <>
                                {!rowClick && solutionHeaderRefer(actionData, item.challenge)}
                                {!rowClick && (
                                    <Typhography>
                                        <Typhography
                                            component='div'
                                            additionalClasses='my-3.5 mx-6 bg-[#F9FAFB] !w-auto items-center p-4 rounded-lg'
                                        >
                                            {solutionStartContent(actionData)}
                                        </Typhography>

                                        <Typhography component='div' additionalClasses='flex mx-6 justify-between'>
                                            <Button
                                                buttonLabel={'Cancel'}
                                                data-testId='InputField-id'
                                                variants='smallWhite'
                                                additionalClasses='font-medium text-21s'
                                                onClick={() => {
                                                    cancelAction && cancelAction();
                                                }}
                                            />
                                            <Button
                                                buttonLabel={'Start Letter'}
                                                data-testId='InputField-id'
                                                variants='smallBlue'
                                                additionalClasses='font-medium text-21s'
                                                onClick={() => {
                                                    selectedSolution &&
                                                        letterBtnClick(selectedSolution, selectedSolutionVariant);
                                                }}
                                            />
                                        </Typhography>
                                    </Typhography>
                                )}
                            </>
                        );
                    } else {
                        return (
                            <>
                                {!rowClick && solutionHeaderRefer(actionData, item.challenge)}
                                {!rowClick && (
                                    <Typhography
                                        component='div'
                                        additionalClasses='my-3.5 mx-6 bg-[#F9FAFB] !w-auto items-center p-4 rounded-lg'
                                    >
                                        {solutionCompleteContent(actionData)}

                                        {showNote && (
                                            <>
                                                <Typhography additionalClasses='text-12s font-medium'>Notes</Typhography>
                                                <NoteEditor
                                                    onAdd={(items: any) =>
                                                        saveSolutionNote && solutionNote(items, item._id)
                                                    }
                                                    cancelAction={cancelAction}
                                                    isOptional
                                                    buttonLabels={{ cancel: 'Cancel', save: 'Complete' }}
                                                />
                                            </>
                                        )}
                                    </Typhography>
                                )}
                            </>
                        );
                    }
                }
                case ActionTypes.FORM: {
                    if (!rowClick && showSolutionLongDescription) {
                        return (
                            <>
                                {!rowClick && solutionHeaderRefer(actionData, item.challenge)}
                                {!rowClick && (
                                    <Typhography>
                                        <Typhography
                                            component='div'
                                            additionalClasses='my-3.5 mx-6 bg-[#F9FAFB] !w-auto items-center p-4 rounded-lg'
                                        >
                                            {solutionStartContent(actionData)}
                                        </Typhography>

                                        <Typhography component='div' additionalClasses='flex mx-6 justify-between'>
                                            <Button
                                                buttonLabel={'Cancel'}
                                                data-testId='InputField-id'
                                                variants='smallWhite'
                                                additionalClasses='font-medium text-21s'
                                                onClick={() => {
                                                    cancelAction && cancelAction();
                                                }}
                                            />
                                            <Button
                                                buttonLabel={'Start Letter'}
                                                data-testId='InputField-id'
                                                variants='smallBlue'
                                                additionalClasses='font-medium text-21s'
                                                onClick={() => {
                                                    selectedSolution &&
                                                        formBtnClick(selectedSolution, selectedSolutionVariant);
                                                }}
                                            />
                                        </Typhography>
                                    </Typhography>
                                )}
                            </>
                        );
                    } else {
                        return (
                            <>
                                {!rowClick && solutionHeaderRefer(actionData, item.challenge)}
                                {!rowClick && (
                                    <Typhography
                                        component='div'
                                        additionalClasses='my-3.5 mx-6 bg-[#F9FAFB] !w-auto items-center p-4 rounded-lg'
                                    >
                                        {solutionCompleteContent(actionData)}

                                        {showNote && (
                                            <>
                                                <Typhography additionalClasses='text-12s font-medium'>Notes</Typhography>
                                                <NoteEditor
                                                    onAdd={(items: any) =>
                                                        saveSolutionNote && solutionNote(items, item._id)
                                                    }
                                                    cancelAction={cancelAction}
                                                    badgeLabel='Complete'
                                                    buttonLabels={{ cancel: 'Cancel', save: 'Complete' }}
                                                />
                                            </>
                                        )}
                                    </Typhography>
                                )}
                            </>
                        );
                    }
                }
                case ActionTypes.RESOLVED:
                    return (
                        <>
                            {!rowClick && solutionHeaderResolved(item.patientSolutions, item.challenge)}
                            {showNote && !rowClick && (
                                <Typhography component='div' additionalClasses='p-4'>
                                    <Typhography additionalClasses='text-12s font-medium'>Notes</Typhography>
                                    <NoteEditor
                                        badgeLabel="Unresolved"
                                        onAdd={(items: any) =>
                                            handleAddChallengeNote(items, item._id, true)
                                        }
                                        cancelAction={() => {
                                            setShowNote && setShowNote(false);
                                            setRowClick && setRowClick(true);
                                        }}
                                        savedItems={savedItem}
                                        buttonLabels={{ cancel: 'Cancel', save: 'Resolve' }}
                                        isChallengeNote={true}
                                        isOptional
                                    />
                                </Typhography>
                            )}
                        </>
                    );

                default:
                    return null;
            }
        };

        return (
            <div ref={rowDetailsComponentRef} className='relative'>
                {rowClick && (
                    <>
                        {solutionNoteContent(item._id)}
                        {solution && !showNoteEdit && solutionContents(solution)}
                    </>
                )}
                {renderContent()}
            </div>
        );
    };

export default RowDetails;
