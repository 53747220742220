import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { FormGenerator, IComponent, ISchema, ISchemaStep, ISelectedValue } from 'dfh-form-generator';
import { Button, ComponentProvider, InfoCard, Typhography } from 'dfh-ui-library';
import { IPatientScreenerProps } from 'utils/types/patient.type';
import { FormikContextType } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores/store';
import { isEqual } from 'lodash';
import { setScreenerData } from 'stores/screenerSlice';
import { ScreenerStatus } from 'utils/enum';
import { IScreenerRequest } from 'utils/types/screener.type';
import { LocalStorageService } from 'utils/storage/LocalStorageService';
import { getNewSchemaWithPrevData, getNewScreenerState } from '../helpers/screenerHelper';

const ScreeningComponent = (props: IPatientScreenerProps, ref: React.Ref<unknown> | undefined) => {
    const [components, setComponents] = React.useState<IComponent[]>();
    const formGeneratorRef = useRef<FormikContextType<any>>(null);
    const dispatch = useDispatch();
    const screenerData = useSelector((state: RootState) => state.screener.data);
    const newSchema = useSelector((state: RootState) => state.schema.schema);
    const [patientSchema, setPatientSchema] = React.useState<ISchema | undefined>(undefined);
    const localStorageService = LocalStorageService();
    useMemo(() => {
        if (props.selectedVersion) {
            setPatientSchema(props.selectedVersion);
        } else {
            const schema = screenerData.filter((data) => isEqual(data.patientId, props.selectedPatient?._id))[0].schema;
            if (schema) {
                if (!props.viewMode && schema.isLocked) {
                    if (newSchema) setPatientSchema(getNewSchemaWithPrevData(newSchema, schema));
                } else {
                    setPatientSchema(schema);
                }
            }
        }
    }, [screenerData, props.selectedPatient, props.viewMode, props.isEditable, props.selectedVersion]);

    const onSubmit = async (_data: any) => {
        saveScreenerChanges(_data);
        props.goToNextStep?.();
    };

    const comSchema = [
        { fieldId: 1, component: 'ButtonGroup' },
        { fieldId: 2, component: 'Input' },
        { fieldId: 3, component: 'ButtonGroupWithInputs' },
        { fieldId: 4, component: 'InputsGroup' },
        { fieldId: 5, component: 'ButtonGroupWithUpload' },
        { fieldId: 6, component: 'AccommodationComponent' },
        { fieldId: 7, component: 'TagSelect' },
        { fieldId: 8, component: 'MultiInputGroups' },
    ];

    useImperativeHandle(ref, () => ({
        handleSubmitClick,
    }));

    const handleSubmitClick = () => {
        // Access the submitForm function through the ref and call it
        formGeneratorRef.current?.submitForm();
    };

    // Detect and save form changes
    const handleOnChange = (changedValue: { [key: number]: any }) => {
        const schemaCopy: ISchemaStep = JSON.parse(JSON.stringify(props.schemaStep));

        Object.entries(changedValue).forEach(([key, updatedValue]) => {
            const fieldId = key;

            if (fieldId) {
                schemaCopy.formRows.forEach((formRow) => {
                    formRow.questionList.forEach((element) => {
                        if (element.question._id === fieldId) {
                            element.question.selected = updatedValue;
                        }
                    });
                });
            }
        });

        if (patientSchema && patientSchema.schemaSteps.length > 0) {
            const newArray = patientSchema.schemaSteps.map((obj) =>
                obj.stepNumber === schemaCopy.stepNumber ? schemaCopy : obj,
            );
            const newState = getNewScreenerState(
                props.selectedPatient?._id,
                { _id: patientSchema._id, schemaSteps: newArray },
                ScreenerStatus.INPROGRESS,
                screenerData,
            );
            if (!props.viewMode && newState.length > 0 && !isEqual(patientSchema.schemaSteps, newArray)) {
                dispatch(setScreenerData(newState));
            }
        }
    };

    // Save screener changes
    const saveScreenerChanges = (values: { [key: string]: ISelectedValue }) => {
        const user = localStorageService.getUser();
        if (props.selectedPatient?._id && user?._id && patientSchema?._id) {
            const req: IScreenerRequest = {
                presentationSchemaId: patientSchema._id,
                patientId: props.selectedPatient?._id,
                addedUserId: user?._id,
                answers: Object.fromEntries(
                    Object.entries(values).filter(([_key, value]) => Object.keys(value).length > 0),
                ),
            };

            const response = props.updateScreenerSteps(req);

            if (response) {
                response.then((result) => {
                    props.getSurvey && props.getSurvey(result?.data?.data);
                });
            }
        }
    };

    return (
        <>
            <Typhography additionalClasses='flex justify-between'>
                <Typhography component='span' additionalClasses='flex mb-4 text-25s font-medium'>
                    {props.schemaStep?.contentTitle}
                </Typhography>
                {props.viewMode && !props.selectedVersion && (
                    <Typhography component='div' additionalClasses='flex mb-4 text-25s font-medium'>
                        <Button
                            buttonLabel='Edit answers'
                            data-testId='InputField-id2'
                            variants='primaryWhite'
                            onClick={props.goToScreenerEdit}
                        />
                    </Typhography>
                )}
            </Typhography>
            {props.schemaStep?.disclaimer && (
                <Typhography component='div' additionalClasses='mb-4'>
                    <InfoCard HeaderText='Disclaimer' text={props.schemaStep?.disclaimer} />
                </Typhography>
            )}
            <ComponentProvider schema={comSchema} setComponents={setComponents}>
                {components && patientSchema?.schemaSteps && (
                    <FormGenerator
                        schema={patientSchema}
                        currentStepNumber={props.schemaStep?.stepNumber}
                        components={components}
                        handleSubmit={onSubmit}
                        handleChanges={handleOnChange}
                        showDefaultSubmitButton={false}
                        formRef={formGeneratorRef}
                        viewMode={props.viewMode}
                        showPreviousData={
                            !props.viewMode &&
                            screenerData.filter((data) => isEqual(data.patientId, props.selectedPatient?._id)).length >
                                0
                                ? true
                                : false
                        }
                    ></FormGenerator>
                )}
            </ComponentProvider>
        </>
    );
};

export default forwardRef(ScreeningComponent);
