import React, { useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const useBlockNavigation = (shouldBlock: boolean, onNavigate?: () => Promise<boolean>) => {
    const { navigator } = React.useContext(NavigationContext);

    useEffect(() => {
        if (!shouldBlock) return;

        const originalPush = navigator.push;
        const originalReplace = navigator.replace;

        navigator.push = (...args) => {
            if (shouldBlock) {
                onNavigate?.().then((isConfirmed) => {
                    if (isConfirmed) {
                        originalPush(...args);
                    }
                });
            } else {
                originalPush(...args);
            }
        };

        navigator.replace = (...args) => {
            if (shouldBlock) {
                onNavigate?.().then((isConfirmed) => {
                    if (isConfirmed) {
                        originalReplace(...args);
                    }
                });
            } else {
                originalReplace(...args);
            }
        };

        return () => {
            navigator.push = originalPush;
            navigator.replace = originalReplace;
        };
    }, [shouldBlock, navigator, onNavigate]);
};

export const BlockNavigation = ({ isBlocked, onNavigate }: { isBlocked: boolean, onNavigate: () => Promise<boolean> }) => {
    useBlockNavigation(isBlocked, onNavigate);
    return null; // This component doesn't render anything
};

export default useBlockNavigation;
