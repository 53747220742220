import React, { ComponentType, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuthRepository } from 'repositories/AuthRepository';
import RouteRegistry from 'routes/routeRegistry';
import { errorMsgInit } from 'utils/init';
import { LocalStorageService } from 'utils/storage/LocalStorageService';
import { ApiResponse, AuthApiRequest, IAuthNewPasswordRequest, IAuthRequest, MfaVerifyApiRequest } from 'utils/types/api.type';
import { ILoginProps, IErrorMsg } from 'utils/types/auth.type';

const loginHOC = <P extends ILoginProps>(WrappedComponent: ComponentType<ILoginProps>) => {
    const LoginDataFetching: React.FC<P> = (props) => {
        const [isLoading, setIsLoading] = React.useState(false);
        const [errorMsg, setErrorMsg] = useState<IErrorMsg>(errorMsgInit);
        const navigate = useNavigate();
        const localStorageService = LocalStorageService();
        const authRepository = useAuthRepository();

        const navigateToSignIn = () => {
            navigate(RouteRegistry.auth.paths.signIn.path);
        };

        const navigateToSignUp = () => {
            navigate(RouteRegistry.auth.paths.signUp.path);
        };

        const navigateToResetPassword = () => {
            navigate(RouteRegistry.auth.paths.reset.path);
        };

        const navigateToHome = () => {
            navigate(RouteRegistry.app.paths.root.path);
        };

        const navigateToVerification = (email: string) => {
            navigate(RouteRegistry.auth.paths.verification.path, { state: { email: email } });
        };

        const navigateToUserDetails = (email: string) => {
            navigate(RouteRegistry.auth.paths.userDetails.path, { state: { email: email } });
        };

        const navigateToRestEmail = () => {
            navigate(RouteRegistry.auth.paths.resetEmail.path);
        };

        const navigateToMfaVerify = (session: string, email: string, password: string) => {
            navigate(RouteRegistry.auth.paths.mfaVerify.path, { state: { session: session, email: email, password: password } });
        }

        // Login Function
        const login = async (req: AuthApiRequest, isResend?: boolean): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const loginResult = await authRepository.login({
                    email: req.email,
                    password: req.password,
                });
                if (loginResult.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: loginResult.error.data.message,
                    });
                if (loginResult.data) {
                    // We need to send user to MFA page
                    if (loginResult.data.data.payload.session) {
                        if (!isResend) {
                            navigateToMfaVerify(loginResult.data.data.payload.session, loginResult.data.data.payload.email, req.password);
                        } else {
                            setIsLoading(false);
                            return loginResult;
                        }

                        // localStorageService.setAuthTokens({
                        //     accessToken: loginResult.data.data.accessToken,
                        //     refreshToken: loginResult.data.data.refreshToken,
                        //     idToken: loginResult.data.data.idToken,
                        //     user: loginResult.data.data.payload,
                        // });
                    }
                }
                setIsLoading(false);
                return loginResult;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        // MFA Verify Function
        const mfaVerify = async (req: MfaVerifyApiRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const mfaVerifyResult = await authRepository.mfaVerify({
                    email: req.email,
                    session: req.session,
                    mfaCode: req?.mfaCode,
                });
                if (mfaVerifyResult.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: mfaVerifyResult.error.data.message,
                    });
                if (mfaVerifyResult.data) {

                    localStorageService.setAuthTokens({
                        accessToken: mfaVerifyResult.data.data.accessToken,
                        refreshToken: mfaVerifyResult.data.data.refreshToken,
                        idToken: mfaVerifyResult.data.data.idToken,
                        user: mfaVerifyResult.data.data.payload,
                    });
                }
                setIsLoading(false);
                return mfaVerifyResult;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        }


        // New Password Function
        const newPassword = async (req: IAuthNewPasswordRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const newPassResult = await authRepository.newPassword({
                    email: req.email,
                    verificationCode: req.verificationCode,
                    newPassword: req.newPassword,
                });
                if (newPassResult.error){
                   throw newPassResult.error;
                }

   
                return newPassResult;
            } catch (apiError:any) {
                // setErrorMsg({
                //     isVisible: true,
                //     type: 'danger',
                //     msg: apiError.data.message
                // });
                toast.error(apiError.data.message)
                setIsLoading(false);
                return;
            }
        };

        // Password Reset Function
        const resetPassword = async (req: IAuthRequest): Promise<ApiResponse | undefined> => {
            try {
                setIsLoading(true);
                const newPassResult = await authRepository.resetPassword({
                    email: req.email,
                });
                if (newPassResult.error)
                    setErrorMsg({
                        isVisible: true,
                        type: 'danger',
                        msg: 'Password reset failed.',
                    });
                setIsLoading(false);
                return newPassResult;
            } catch (apiError) {
                setIsLoading(false);
                return;
            }
        };

        const apiMethods = {
            login: login,
            newPassword: newPassword,
            resetPassword: resetPassword,
            mfaVerify: mfaVerify
        };

        const navigateMethods = {
            navigateToSignIn: navigateToSignIn,
            navigateToSignUp: navigateToSignUp,
            navigateToResetPassword: navigateToResetPassword,
            navigateToRestEmail: navigateToRestEmail,
            navigateToVerification: navigateToVerification,
            navigateToUserDetails: navigateToUserDetails,
            navigateToHome: navigateToHome,
        };

        return (
            <WrappedComponent
                {...props}
                isLoading={isLoading as any}
                errorMsg={errorMsg as any}
                apiMethods={apiMethods as any}
                navigateMethods={navigateMethods as any}
            />
        );
    };

    return LoginDataFetching;
};

export default loginHOC;
